<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    const savedToken = window.localStorage.getItem('serverToken');

    if (!savedToken) {
      this.$router.push('/login');
    } else {
      this.$store.commit('setServerToken', savedToken);
    }
  },
};
</script>

<style>
body {
  height: 100vh;
  margin: 0;
  width: 100vw;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
