<template>
  <div class="home">
    <div class="title-container">
      <img src="img/LeftBanner.png" alt="banner"/>
    </div>
    <div class="wheel-container">
      <SpinningWheel ref="wheel"/>
    </div>
    <div class="button-container">
      <div
        :class="$store.state.spinning ? 'button disabled' : 'button'"
        @click="spin"
      />
    </div>
  </div>
</template>

<script>
import SpinningWheel from '@/components/SpinningWheel.vue';

export default {
  name: 'HomeView',
  components: {
    SpinningWheel,
  },
  methods: {
    spin() {
      this.$refs.wheel.startSpinning();
    },
  },
};
</script>

<style>
.button-container .button {
  background-image: url('@/assets/SpinButton.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 21vh;
  width: 21vh;
  position: absolute;
  bottom: 8.5vh;
  right: 8.5vh;
}

.button-container .button.disabled {
  opacity: 0.2;
}

.home {
  display: flex;
  height: 100%;
  width: 100%;
}

.title-container,
.button-container {
  width: 10%;
}

.title-container {
  display: flex;
}

.title-container img {
  height: 100%;
}

.wheel-container {
  display: flex;
  width: 80%;
}
</style>
